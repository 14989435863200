canvas {
  position: absolute;
  z-index: $default;
  cursor: pointer;
}

#preloader {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: $loader;
  pointer-events: none;
  background: url("../static/background@2x.jpg");
  background-size: cover;
  background-position: center;
  @include overlay;
  &.hidden {
    opacity: 0;
  }
}
