// Colors
$black: #0e0e0e;
$white: #ffffff;
$green: #00ff99;
$yellow: #fff35c;
$blue: #00ccff;
$purple: #9933ff;
$red: #ff6666;
$pink: #d556b2;
$secondary-blue: #4a92f7;
$indigo: #5d5ef6;
$teal: #00e6cc;

$grey-darkest: #121212;
$grey-dark: #2e2e2e;
$grey-medium: #5c5c5c;
$grey: #a6a6a6;
$grey-light: #d8d8d8;

$glass: rgba($black, 0.5);

// Spacing
$iconMargin: 15px;
$sitePadding: 30px;
$mobilePadding: 10px;

// Element Sizing
$iconSize: 50px;
$preloader: 100px;

// Z-Index Layers

$background: -1;
$default: 1;
$overlay: 10;
$modal: 100;
$loader: 500;
$nav: 1000;
$warning: 10000;

// Values
$overlay-transparency: 0.9;
$icon-opacity: 0.5;
$icon-hover-opacity: 0.9;
