html {
  -webkit-text-size-adjust: 100%;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-size-adjust: 100%;
  width: 100%;
  min-height: 100vh;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizespeed;
  position: relative;
}

*, :after, :before {
  box-sizing: border-box;
}

a:not([class]) {
  text-decoration-skip-ink: auto;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

:focus {
  outline: 0;
}

main, article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

input:required, input {
  box-shadow: none;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
  -webkit-box-shadow: inset 0 0 0 30px #fff;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="search"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

input:focus {
  outline: none;
}

audio, canvas, video {
  max-width: 100%;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden] {
  display: none;
}

a:active, a:hover {
  outline: none;
}

img {
  max-width: 100%;
  vertical-align: middle;
  height: auto;
  display: inline-block;
}

picture {
  display: inline-block;
}

button, input {
  line-height: normal;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  background: none;
  border: 0;
}

button[disabled], html input[disabled] {
  cursor: default;
}

[disabled] {
  pointer-events: none;
}

input[type="checkbox"], input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner, input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button {
  background: none;
  border: 0;
}

textarea {
  vertical-align: top;
  resize: vertical;
  overflow: auto;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  text-indent: 0;
}

hr {
  box-sizing: content-box;
  height: 1px;
  page-break-after: always;
  width: 100%;
  background: #000;
  border: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 100%;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

code, kbd, pre, samp {
  font-family: monospace;
}

small {
  font-size: 75%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -5px;
}

sup {
  top: -5px;
}

button, input, optgroup, select, textarea {
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner {
  border-style: none;
  outline: 0;
  padding: 0;
}

legend {
  color: inherit;
  white-space: normal;
  max-width: 100%;
  width: 100%;
  border: 0;
  display: block;
}

fieldset {
  min-width: 0;
}

body:not(:-moz-handler-blocked) fieldset {
  display: block;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

template {
  display: none;
}

@font-face {
  font-family: Interface;
  src: url("InterFace_Bd.5dd664a1.woff2") format("woff2"), url("InterFace_Bd.e909e612.woff") format("woff");
  font-weight: bold;
  font: normal;
  font-display: swap;
}

@font-face {
  font-family: Interface;
  src: url("InterFace_Rg.f8380bd5.woff2") format("woff2"), url("InterFace_Rg.80e723b5.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Nexa;
  src: url("Nexa_Black.94986f85.woff2") format("woff2"), url("Nexa_Black.5a5da492.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

html, body {
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: #0e0e0e;
  font-family: Interface, sans-serif;
  overflow: hidden;
}

@media (max-width: 768px) {
  html, body {
    min-height: 100vh;
  }
}

::selection {
  color: #fff;
  background-color: #5d5ef6;
}

img {
  -webkit-user-select: none;
  user-select: none;
}

body {
  background: url("background@2x.1c64c254.jpg") center / cover;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  display: flex;
}

@media (max-width: 768px) {
  body {
    gap: 5vh;
    padding: 25px 20px 100px;
  }
}

header, main, nav, ul, li, footer {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.icon {
  width: 30px;
  height: 30px;
  opacity: .5;
  position: relative;
}

.icon:hover {
  opacity: .9;
}

header nav {
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

header nav a {
  cursor: pointer;
  display: inline-block;
}

header nav #logo {
  width: 28px;
  height: 36px;
  background: url("logo.ee9cddf7.svg") center / contain no-repeat;
}

header nav #overlay-toggle {
  background: url("info.e662c294.svg") no-repeat;
}

header nav #overlay-toggle.open {
  background: url("close.f6ee8daf.svg") no-repeat;
}

header nav #overlay-toggle:before {
  content: "More Info";
  color: #5c5c5c;
  width: 100px;
  text-align: right;
  opacity: 0;
  padding-right: 5px;
  position: absolute;
  top: 7px;
  left: -100px;
}

header nav #overlay-toggle:hover:before {
  opacity: 1;
}

header nav #staff-grid {
  width: 42px;
  height: 42px;
  opacity: .9;
  background: url("avatar.a5419d89.svg") no-repeat;
  position: fixed;
  bottom: 30px;
  left: 30px;
}

header nav #staff-grid:hover {
  opacity: 1;
  background: url("avatar-hover.c6ef4e33.svg") no-repeat;
}

header nav #staff-grid:before {
  content: "All Staff";
  color: #5c5c5c;
  width: 100px;
  text-align: left;
  opacity: 0;
  padding-left: 5px;
  position: absolute;
  top: 10px;
  right: -100px;
}

header nav #staff-grid:hover:before {
  opacity: 1;
}

@media (max-width: 768px) {
  header nav #staff-grid {
    height: 40px;
    width: 180px;
    background: #2e2e2e url("avatar.a5419d89.svg") no-repeat;
    border-radius: 4px;
    bottom: 65px;
    left: calc(50% - 90px);
  }

  header nav #staff-grid:hover {
    background: #2e2e2e url("avatar-hover.c6ef4e33.svg") no-repeat;
  }

  header nav #staff-grid:before {
    opacity: 1;
    color: #a6a6a6;
    height: 100%;
    width: 125px;
    white-space: nowrap;
    content: "Or Open Directory";
    padding-top: 10px;
    top: 0;
    left: 45px;
  }

  body.overlay-open #staff-grid {
    display: none;
  }
}

main {
  max-width: 80vh;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

main canvas {
  width: 100%;
  height: 100%;
  transform: translateY(-15%)scale(1.2);
}

@media (max-width: 768px) {
  main canvas {
    z-index: 1;
    transform: translateY(-20%)scale(1.6);
  }
}

main img {
  pointer-events: none;
}

main #yearbook-branding {
  width: 80%;
}

@media (max-width: 768px) {
  main #yearbook-branding {
    width: 100%;
    max-width: 50vh;
  }
}

main ul {
  width: auto;
  z-index: 10;
  align-items: flex-end;
  gap: 45px;
  margin: 0 auto;
}

main ul li {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
}

main ul li img {
  opacity: .5;
}

main ul li h5 {
  width: 100%;
  color: #5c5c5c;
  pointer-events: none;
  font-weight: bold;
}

main ul li:hover img {
  opacity: .66;
}

main ul li:hover h5 {
  color: #a6a6a6;
}

@media (max-width: 768px) {
  main ul {
    display: none;
  }
}

main #play {
  z-index: 100;
  position: relative;
}

button {
  color: #fff;
  -webkit-user-select: none;
  user-select: none;
  background: linear-gradient(to top right, #93f, #5d5ef6);
  border-radius: 4px;
  margin-top: 30px;
  padding: 24px 100px;
  font-family: Nexa;
  font-size: 26px;
  font-weight: 900;
  transition: color .33s ease-in-out;
  box-shadow: -1px 1px #4d0099, -2px 2px #4d0099, -3px 3px #4d0099, -4px 4px #4d0099, -5px 5px #4d0099, -6px 6px #4d0099, -7px 7px #4d0099, -8px 8px #4d0099;
}

button:hover {
  background: linear-gradient(to top right, #d3f, #8f5df6);
  transform: translate(-2px, 2px);
  box-shadow: -1px 1px #306, -2px 2px #306, -3px 3px #306, -4px 4px #306, -5px 5px #306, -6px 6px #306;
}

@media (max-width: 768px) {
  button {
    padding: 24px 80px;
  }
}

footer {
  text-align: center;
  width: auto;
  pointer-events: none;
  flex-direction: column;
  gap: 10px;
  margin: 0 auto;
}

footer p {
  color: #5c5c5c;
  pointer-events: none;
  font-size: 15px;
}

@media (max-width: 768px) {
  footer.network-warning {
    opacity: 0;
    pointer-events: none;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 799px) {
  body {
    padding: 60px 30px 20px;
  }

  main {
    gap: 0;
  }

  header {
    padding: 30px;
    position: absolute;
    top: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 799px) and (max-height: 625px) {
  footer {
    display: none;
  }
}

aside {
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 100;
  background-color: #0e0e0ed9;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

aside.open {
  display: block;
}

aside#overlay {
  animation: .2s ease-in-out forwards fadeIn;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    display: none;
  }

  to {
    opacity: 1;
    display: flex;
  }
}

aside.banner {
  height: 75px;
  z-index: 10000;
  width: calc(100% - 50px);
  background: linear-gradient(to top right, #f66, #d556b2);
  border-radius: 8px;
  align-items: center;
  padding: 0 30px;
  animation: .5s cubic-bezier(.175, .885, .32, 1.275) forwards bounceIn;
  display: none;
  top: 25px;
  left: 25px;
  box-shadow: -1px 1px #b22c8d, -2px 2px #b22c8d, -3px 3px #b22c8d, -4px 4px #b22c8d, -5px 5px #b22c8d;
}

@keyframes bounceIn {
  from {
    opacity: 0;
    display: none;
    transform: scale(.9);
  }

  to {
    opacity: 1;
    display: flex;
    transform: scale(1);
  }
}

aside.banner .modal-content {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

aside.banner .modal-content .left, aside.banner .modal-content .right {
  align-items: center;
  gap: 5px;
  display: flex;
}

aside.banner .modal-content .left h6, aside.banner .modal-content .right h6 {
  margin: 0;
  line-height: 150%;
}

aside.banner .modal-content .left p, aside.banner .modal-content .left h6, aside.banner .modal-content .left a, aside.banner .modal-content .right p, aside.banner .modal-content .right h6, aside.banner .modal-content .right a {
  color: #fff;
}

aside.banner .modal-content .left a#hide-banner, aside.banner .modal-content .right a#hide-banner {
  opacity: .75;
  background: url("close.f6ee8daf.svg") 0 0 / contain no-repeat;
  padding: 10px;
}

aside.banner .modal-content .left a#hide-banner:hover, aside.banner .modal-content .right a#hide-banner:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  aside.banner {
    visibility: hidden;
    display: none !important;
  }
}

aside.slide-up {
  -webkit-backdrop-filter: blur();
  backdrop-filter: blur();
  height: 100%;
  opacity: 0;
  visibility: hidden;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  z-index: 1000;
  background-color: #0e0e0e80;
  transition: opacity .3s, visibility .3s;
  display: flex;
}

aside.slide-up a#close {
  width: 36px;
  height: 36px;
  background: url("close.f6ee8daf.svg") no-repeat;
  position: absolute;
  top: 30px;
  right: 15px;
}

aside.slide-up .modal-content {
  height: auto;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  background: linear-gradient(to top right, #93f, #5d5ef6);
  border-radius: 24px 24px 0 0;
  flex-direction: column;
  justify-content: center;
  align-self: end;
  gap: 15px;
  margin: 0 auto;
  padding: 45px 15px 20px;
  transition: transform .5s;
  display: flex;
  transform: translateY(100%);
}

aside.slide-up .modal-content h6, aside.slide-up .modal-content p {
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px 2px #0e0e0e26;
  margin: 0;
}

aside.slide-up .modal-content h6 {
  font-family: Nexa;
  font-size: 22px;
}

aside.slide-up .modal-content p {
  opacity: .9;
}

aside.slide-up .modal-content a#play-anyways {
  text-align: center;
  color: #fff;
  opacity: .85;
  margin-top: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  font-weight: bold;
}

aside.slide-up .modal-content a#share-link {
  width: 97.5%;
  margin: 0 auto;
}

aside.slide-up .modal-content a#share-link button {
  width: 100%;
  background: linear-gradient(to top right, #0e0e0e, #2e2e2e);
  margin-top: 10px;
  padding: 24px;
  font-family: Nexa;
  font-size: 17px;
  font-weight: 900;
  transition: color .33s ease-in-out;
}

aside.slide-up.open {
  opacity: 1;
  visibility: visible;
}

aside.slide-up.open .modal-content {
  transform: translateY(0);
}

aside .overlay-content {
  width: 80%;
  max-width: 550px;
  flex-direction: column;
  justify-content: center;
  gap: 50px;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: visible;
  transform: translate(-50%, -50%);
}

aside .overlay-content .intro p {
  color: #d8d8d8;
}

aside .overlay-content .intro p span {
  color: #a6a6a6;
  font-size: 17px;
}

aside .overlay-content .intro img {
  height: 200px;
  width: auto;
  margin-bottom: -25px;
  transition: all .33s ease-in-out;
  transform: translateX(-100px);
}

aside .overlay-content .intro img:hover {
  filter: hue-rotate(270deg) brightness(2);
}

@media (max-width: 768px) {
  aside .overlay-content .intro img {
    display: none;
  }
}

aside .overlay-content .footer-info {
  flex-direction: row;
  gap: 30px;
  display: flex;
}

@media (max-width: 768px) {
  aside .overlay-content .footer-info section:first-child {
    width: 60%;
  }

  aside .overlay-content .footer-info section:nth-child(2) {
    width: 40%;
  }

  aside .overlay-content .footer-info section:nth-child(2) ul {
    flex-wrap: wrap;
  }
}

aside .overlay-content .footer-info ul {
  width: auto;
  justify-content: flex-start;
  gap: 10px;
}

aside .overlay-content .footer-info ul li {
  width: auto;
}

aside .overlay-content .footer-info ul li a {
  font-size: 17px;
  text-decoration: none;
}

aside .overlay-content .footer-info ul li a:hover {
  text-decoration: underline;
}

aside .overlay-content .footer-info ul li:nth-child(1) a {
  color: #0f9;
}

aside .overlay-content .footer-info ul li:nth-child(2) a {
  color: #0cf;
}

aside .overlay-content .footer-info ul li:nth-child(3) a {
  color: #4a92f7;
}

aside .overlay-content .footer-info ul li:nth-child(4) a {
  color: #93f;
}

aside .overlay-content .footer-info ul li:nth-child(5) a {
  color: #d556b2;
}

aside .overlay-content .footer-info ul li:nth-child(6) a {
  color: #f66;
}

aside .overlay-content .footer-info ul:hover li:not(:hover) a {
  opacity: .25;
}

@media (max-width: 768px) {
  aside .overlay-content {
    height: 75%;
    justify-content: space-between;
    gap: 15px;
    margin-top: 15%;
    padding: 0;
  }
}

aside .overlay-content p {
  color: #a6a6a6;
  margin-bottom: 15px;
  font-size: 22px;
  line-height: 150%;
}

aside .overlay-content p a {
  color: #d8d8d8;
}

aside .overlay-content p.small {
  font-size: 15px;
}

@media (max-width: 768px) {
  aside .overlay-content p {
    margin-bottom: 15px;
    font-size: 20px;
  }

  aside .overlay-content p.small {
    font-size: 17px;
  }
}

aside .modal-content p, aside .overlay-content p {
  color: #a6a6a6;
}

aside .modal-content p span, aside .overlay-content p span {
  color: #a6a6a6;
  font-size: 17px;
}

aside .modal-content h6, aside .overlay-content h6 {
  color: #a6a6a6;
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 700;
}

@media (max-width: 768px) {
  aside .modal-content h6, aside .overlay-content h6 {
    margin-bottom: 15px;
  }
}

body.safari aside.banner {
  display: flex;
}

canvas {
  z-index: 1;
  cursor: pointer;
  position: absolute;
}

#preloader {
  width: 100%;
  height: 100vh;
  z-index: 500;
  pointer-events: none;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background: #0e0e0ee6 url("background@2x.1c64c254.jpg") center / cover;
  position: fixed;
}

#preloader.hidden {
  opacity: 0;
}

/*# sourceMappingURL=index.cf380c23.css.map */
