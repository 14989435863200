aside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($black, 0.85);
  backdrop-filter: blur(10px);
  display: none;
  z-index: $modal;

  // active
  &.open {
    display: block;
  }

  &#overlay {
    animation: fadeIn 0.2s ease-in-out forwards;
    @keyframes fadeIn {
      from {
        display: none;
        opacity: 0;
      }
      to {
        display: flex;
        opacity: 1;
      }
    }
  }

  // desktop safari banner warning
  &.banner {
    height: 75px;
    display: none;

    @include gradient($red, $pink);
    border-radius: 8px;

    z-index: $warning;
    align-items: center;

    padding: 0 $sitePadding;

    width: calc(100% - 50px);
    left: 25px;
    top: 25px;
    @include shadow($pink, 5, 15%);
    animation: bounceIn 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;

    @keyframes bounceIn {
      from {
        display: none;
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        display: flex;
        opacity: 1;
        transform: scale(1);
      }
    }

    .modal-content {
      flex-direction: row;
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
      .left,
      .right {
        display: flex;
        align-items: center;
        gap: 5px;

        h6 {
          margin: 0;
          line-height: 150%;
        }
        p,
        h6,
        a {
          color: $white;
        }
        a#hide-banner {
          background: url("./assets/images/icons/close.svg") no-repeat;

          padding: 10px;
          background-size: contain;
          opacity: 0.75;
          &:hover {
            opacity: 1;
          }
        }
      }
    }

    @include mobile {
      display: none !important;
      visibility: hidden;
    }
  }

  // mobile slideup warning
  &.slide-up {
    background-color: rgba($black, 0.5);
    backdrop-filter: blur(0px);
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
    display: flex;
    backdrop-filter: blur(10px);
    z-index: $nav;

    a#close {
      background: url("./assets/images/icons/close.svg") no-repeat;
      width: 36px;
      height: 36px;
      position: absolute;
      top: 30px;
      right: 15px;
    }

    .modal-content {
      height: auto;
      align-self: end;
      @include gradient($purple, $indigo);

      backdrop-filter: blur(5px);

      width: 100%;
      border-radius: 24px 24px 0 0;
      margin: 0 auto;
      padding: 45px 15px 20px 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15px;

      transform: translateY(100%);
      transition: transform 0.5s;

      display: flex;

      h6,
      p {
        color: $white;
        text-align: center;
        margin: 0;
        text-shadow: 2px 2px 2px rgba($black, 0.15);
      }

      h6 {
        font-size: 22px;
        font-family: "Nexa";
      }

      p {
        opacity: 0.9;
      }

      a#play-anyways {
        text-align: center;
        color: $white;
        opacity: 0.85;
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 0;
        padding-bottom: 0;
      }

      a#share-link {
        width: 97.5%;
        margin: 0 auto;

        button {
          font-family: "Nexa";
          font-weight: 900;
          font-size: 17px;
          padding: 24px 24px;
          width: 100%;
          margin-top: 10px;
          transition: color 0.33s ease-in-out;
          @include gradient($black, $grey-dark);
        }
      }
    }

    &.open {
      opacity: 1;
      visibility: visible;

      .modal-content {
        transform: translateY(0);
      }
    }
  }

  .overlay-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 550px;
    overflow: visible;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    justify-content: center;

    .intro {
      p {
        color: $grey-light;
        span {
          font-size: 17px;
          color: $grey;
        }
      }
      img {
        height: 200px;
        width: auto;
        margin-bottom: -25px;
        transform: translateX(-100px);
        transition: 0.33s ease-in-out;

        &:hover {
          filter: hue-rotate(270deg) brightness(2);
        }
        @include mobile {
          display: none;
        }
      }
    }
    .footer-info {
      display: flex;
      flex-direction: row;
      gap: 30px;

      @include mobile {
        //flex-direction: column;
        //justify-content: flex-end;
        section:first-child {
          width: 60%;
        }
        section:nth-child(2) {
          width: 40%;
          ul {
            flex-wrap: wrap;
          }
        }
      }

      ul {
        justify-content: flex-start;
        gap: 10px;
        width: auto;

        li {
          width: auto;
          a {
            font-size: 17px;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:nth-child(1) {
            a {
              color: $green;
            }
          }
          &:nth-child(2) {
            a {
              color: $blue;
            }
          }
          &:nth-child(3) {
            a {
              color: $secondary-blue;
            }
          }
          &:nth-child(4) {
            a {
              color: $purple;
            }
          }
          &:nth-child(5) {
            a {
              color: $pink;
            }
          }
          &:nth-child(6) {
            a {
              color: $red;
            }
          }
        }

        &:hover {
          li:not(:hover) {
            a {
              opacity: 0.25;
            }
          }
        }
      }
    }

    @include mobile {
      gap: 15px;
      padding: 0;
      justify-content: space-between;
      height: 75%;
      margin-top: 15%;
    }

    p {
      margin-bottom: 15px;
      line-height: 150%;
      color: $grey;
      font-size: 22px;

      a {
        color: $grey-light;
      }

      &.small {
        font-size: 15px;
      }

      @include mobile {
        font-size: 20px;
        margin-bottom: 15px;

        &.small {
          font-size: 17px;
        }
      }
    }
  }

  .modal-content,
  .overlay-content {
    p {
      color: $grey;
      span {
        font-size: 17px;
        color: $grey;
      }
    }
    h6 {
      color: $grey;
      margin-bottom: 25px;
      font-weight: 700;
      font-size: 15px;
      @include mobile {
        margin-bottom: 15px;
      }
    }
  }
}

body.safari {
  aside.banner {
    display: flex;
  }
}
